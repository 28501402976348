import type { NextPage } from 'next';

import { NotFound } from 'components/errors';
import { Head } from 'components/head';

const ErrorPage: NextPage = () => (
    <>
        <Head title='Упс, НЛО побывало здесь раньше тебя' />
        <NotFound />
    </>
);

// eslint-disable-next-line import/no-default-export
export { ErrorPage as default };
